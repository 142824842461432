import { Unsubscribe } from './base/unsubscribe';
import { takeUntil } from 'rxjs/operators';
import { BrandService } from './services/brand.service';
import { LogService } from 'ngx-log-service';
import { LanguageParam } from './types/LanguageParam';
import { Site } from './../utility/utilityfunctions';
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-notify-bar',
	templateUrl: './notify-bar.component.html',
	styleUrls: ['./notify-bar.component.scss'],
})
export class NotifyBarComponent extends Unsubscribe implements OnInit, OnChanges {
	@Input() currentLang: LanguageParam;
	@Input() contentfulHeader: string;
	@Input() brandValues: Object;
	@Input() currentBrand: Site;

	disclosureCollapsed = localStorage.getItem('disclosureCollapsed')
		? JSON.parse(localStorage.getItem('disclosureCollapsed'))
		: true;

	constructor(
		private logService: LogService,
		private brandService: BrandService
	) {
		super(new Subject<boolean>());
	}

	ngOnInit() {
		this.logService.namespace = 'NotifyBarComponent';
	}

	ngOnChanges() {
		if (this.currentLang) {
			this.brandService.siteEmulation.pipe(
				takeUntil(this.killSubscription))
				.subscribe(([newSite, lang]) => {
					this.currentBrand = newSite;
					const entryId = this.currentBrand.entryIds.filter(x => x.name === this.contentfulHeader)[0].id;
					this.brandService.injectBrand(entryId, lang);
				});
		}
	}

	toggleDisclosure() {
		this.disclosureCollapsed = !this.disclosureCollapsed;
		localStorage.setItem('disclosureCollapsed', `${this.disclosureCollapsed}`);
	}

	get ariaLabel() {
		return this.disclosureCollapsed ? 
			"Expand details about our business model" : 
			"Collapse details about our business model";
	}

	get ariaExpandedStatus() {
		return !this.disclosureCollapsed;
	}
}
