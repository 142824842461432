export class RewardsDetails {
	RewardId: number;
	ProgramId: number;
    ProgramName: string;
    EarnedDate: string;
    ExpiredDate: string;
    RedeemedDate: string;
    Status: string;
    StatusCode: number;
}
