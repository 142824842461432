import { Store } from '../../shared/models/store';

export class Account {
	AccountID: string;
	PostalCode: string;
	PhoneNumber: string;
	CommunicationEmail: string;
	LoginEmail: string;
	SendText: boolean;
	SendEmails: boolean;
	MobileNumber: string;
	PreferredStore: Store;
	LanguageCode: number;
	FirstName: string;
	LastName: string;
	IsCellPhone: boolean;
	DateOfBirth: string;
	Address1: string;
	Address2: string;
	City: string;
	State: string;
	Country: string;
	Language: string;
	IsMadAccount: boolean;
	IsInactive: boolean;
}
