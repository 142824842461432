export class User {
	// Auth 0 centric values
    AccountID: string;
    ObjectID: string;
	IsSocialLogin: boolean;
	FirstTimeSignup: boolean;
	IsSupportSignup: boolean;
	Utm_Source: string;
	Utm_Medium: string;
	Utm_Content: string;
	Utm_Campaign: string;
	LoginEmail: string;
}
