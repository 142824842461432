import { Injectable } from '@angular/core';
import { ILogListener, ALL, LogLevel, ILogMessage } from 'ngx-log-service';
import { environment } from '../../../environments/environment';
import { AppInsightsListener } from './app-insights.listener';

@Injectable()
export class LoggerListener implements ILogListener {
	namespace = ALL;
	level = LogLevel.All;

	constructor(private appInsightsListener: AppInsightsListener) { }

	onLog(namespace: string, level: LogLevel, logMessage: ILogMessage) {
		const loggerLevel = this.determineLoggerLevel(level);

		if (!environment.production) {
			console.log('Source: ' + namespace + ' // Level ' + level + ': ' + loggerLevel + ' // Data: ', logMessage);
		}

		const appInsightsErrorMsg = 'Source: ' + namespace + ' // Level ' + level + ': ' + loggerLevel + ' // Message: ' + logMessage.message + ' // Data: ' + JSON.stringify(logMessage.obj);

		this.appInsightsListener.trackException(appInsightsErrorMsg);
	}

	determineLoggerLevel(level: LogLevel): string {
		let logLevel: string;

		switch (level) {
			case 1:
			case 3:
			default:
				logLevel = 'Info';
				break;
			case 2:
				logLevel = 'Debug';
				break;
			case 4:
				logLevel = 'Warn';
				break;
			case 5:
				logLevel = 'Error';
				break;
			case 6:
				logLevel = 'Fatal';
				break;
		}

		return logLevel;
	}
}
