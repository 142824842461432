import { filter, first } from 'rxjs/operators';
import { Router, ActivatedRoute, Params, RouterEvent, NavigationEnd } from '@angular/router';
import { LanguageParam } from './../types/LanguageParam';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

import { UtilityFunctions, Site, Sites } from '../../utility/utilityfunctions';
import { ContentfulService } from './contentful.service';

// Logging
import { LogService } from 'ngx-log-service';

@Injectable()
export class BrandEmulationService {
	currentLang: LanguageParam = '';

	constructor(
		private logService: LogService,
		private contentfulService: ContentfulService,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.logService.namespace = 'BranEmulationService';
		this.eventStream = new BehaviorSubject<[Site, LanguageParam]>(
			[UtilityFunctions.siteCheck.getDefaultBrand(), this.currentLang]
		);

		this.router.events.pipe(
			filter((event: RouterEvent) => event instanceof NavigationEnd)
			).subscribe(() => {
				let r = this.route;
				while (r.firstChild) {
					r = r.firstChild;
				}
				r.params.pipe(first()).subscribe((params: Params) => {
					this.routeParamsChangeSource.next(params);
					this.currentLang = params['lang'] || '';
					this.streamBrandChange();
				});
		});
	}

	private routeParamsChangeSource = new ReplaySubject<Params>();
	routeParamsChange$ = this.routeParamsChangeSource.asObservable();
	private eventStream: BehaviorSubject<[Site, LanguageParam]>;
	private currentBrand: Site = UtilityFunctions.siteCheck.getDefaultBrand();

	injectBrand(entryId: string, lang: LanguageParam): void {
		// Inject Contentful content
		this.contentfulService.injectContent(
			entryId,
			this.determineLocale(this.currentBrand, lang)
		);
	}

	getContentfulEntry(entryId: string, lang: LanguageParam): any {
		return this.contentfulService.getContentfulEntry(entryId, lang);
	}

	determineSite(): [Site, LanguageParam] {
		const brand: Site = this.getBrand();

		return [Sites.mappings.filter(
			(s) => s.url === brand.url || s.returnUrl === brand.returnUrl
		)[0], this.currentLang];
	}

	determineLocale(site: Site, lang: LanguageParam = ''): string {
		return UtilityFunctions.siteCheck.determineLanguage(
			lang,
			site
		);
	}

	get siteEmulation(): Observable<[Site, LanguageParam]> {
		return this.eventStream;
	}

	getBrand(): Site {
		return this.currentBrand;
	}

	setBrand(brand: string): void {
		this.currentBrand = UtilityFunctions.siteCheck.determineSiteByAlias(
			brand
		);
		this.streamBrandChange();
	}

	setLanguage(language: LanguageParam): void {
		this.currentLang = language;
		this.streamBrandChange();
	}

	streamBrandChange(): void {
		this.eventStream.next([this.currentBrand, this.currentLang]);
	}
}
