import { Site, UtilityFunctions } from './../../utility/utilityfunctions';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

import { LoyaltyDetails } from '../../shared/models/loyaltydetails';
import { BrandService } from '../../shared/services/brand.service';
import { LoyaltyService } from '../../shared/services/loyalty.service';
import { AuthenticationService } from '../../login/services/authentication.service';
import { delay } from 'q';

import { Unsubscribe } from '../../shared/base/unsubscribe';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '../../../environments/environment';

// Logging
import { LogService } from 'ngx-log-service';

const { siteCheck: { isCanadaBrand } } = UtilityFunctions;

@Injectable()
export class ProgramGuard extends Unsubscribe implements CanActivate {
	constructor(private router: Router,
		private loyaltyService: LoyaltyService,
		private logService: LogService,
		private brandService: BrandService,
		public authenticationService: AuthenticationService) {

		super(new Subject<boolean>());

		this.loyaltyService.loyaltyDetails
			.subscribe(loyaltyData => {
				this.loyaltyDetails = loyaltyData;
			}, error => {
				this.logService.fatal('Fatal error subscribing to Loyalty Details', { data: error });
			});

		this.brandService.siteEmulation
			.pipe(takeUntil(this.killSubscription))
			.subscribe(
				([brand]) => {
					this.authenticationService.currentBrand = brand;
					this.currentBrand = this.brandService.getBrand();
				}
			);
	}

	currentBrand: Site;
	loyaltyDetails: LoyaltyDetails = new LoyaltyDetails();

	async canActivate() {
		while (this.loyaltyService.isProcessing()) {
			await delay(500);
		}

		if (isCanadaBrand(this.currentBrand) && environment.featureFlags.flexpoints.toLowerCase() === 'true') {
			return true;
		}

		if (this.loyaltyService.hasPoints() && !isCanadaBrand(this.currentBrand)) {
			return true;
		}

		const langParam = sessionStorage.getItem('lang') || '';

		let pathParam = '';
		if (UtilityFunctions.siteCheck.isLanguageOption(langParam)) {
			pathParam = `/${langParam}`;
		}

		this.router.navigate([`/aboutprogram${pathParam}`]);
		return false;
	}
}
