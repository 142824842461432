import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppInsights } from 'applicationinsights-js';

@Injectable()
export class AppInsightsListener {
	private config: Microsoft.ApplicationInsights.IConfig = {
		instrumentationKey: environment.app_insights_key,
		isCookieUseDisabled: true
	};

	constructor() {
		if (!AppInsights.config) {
			AppInsights.downloadAndSetup(this.config);
		}
	}

	logPageView(name?: string, url?: string, properties?: any,
		measurements?: any, duration?: number) {
		AppInsights.trackPageView(name, url, properties, measurements, duration);
	}

	logEvent(name: string, properties?: any, measurements?: any) {
		AppInsights.trackEvent(name, properties, measurements);
	}

	trackException(errMsg: string) {
		AppInsights.trackTrace(errMsg);
	}
}
