import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

export class Unsubscribe implements OnDestroy {
	constructor(public killSubscription: Subject<boolean>) { }

	ngOnDestroy() {
		this.killSubscription.next(true);
	}
}
