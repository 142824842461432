import { environment } from './../../../environments/environment';
import { EnvType } from './../../../environments/env.enum';
import { filter, first } from 'rxjs/operators';
import { NavigationEnd, Router, ActivatedRoute, RouterEvent, Params } from '@angular/router';
import { LanguageParam } from './../types/LanguageParam';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

import { UtilityFunctions, Site } from '../../utility/utilityfunctions';
import { ContentfulService } from './contentful.service';

// Logging
import { LogService } from 'ngx-log-service';
@Injectable()
export class BrandService {
	currentLang: LanguageParam = '';

	constructor(
		private logService: LogService,
		private contentfulService: ContentfulService,
		private router: Router,
		private route: ActivatedRoute
	) {
		this.logService.namespace = 'BrandService';
		this.eventStream = new BehaviorSubject<[Site, LanguageParam]>(this.determineSite());

		this.router.events.pipe(
			filter((event: RouterEvent) => event instanceof NavigationEnd)
			).subscribe(() => {
				let r = this.route;
				while (r.firstChild) {
					r = r.firstChild;
				}
				r.params.pipe(first()).subscribe((params: Params) => {
					this.routeParamsChangeSource.next(params);
					this.currentLang = params['lang'] || '';
					this.streamBrandChange();
				});
		});
	}

	private routeParamsChangeSource = new ReplaySubject<Params>();
	routeParamsChange$ = this.routeParamsChangeSource.asObservable();
	private eventStream: BehaviorSubject<[Site, LanguageParam]>;
	private currentBrand: Site = this.determineSite()[0];

	injectBrand(entryId: string, lang: LanguageParam): void {
		// Inject Contentful content
		this.contentfulService.injectContent(
			entryId,
			this.determineLocale(this.currentBrand, lang)
		);
	}

	getContentfulEntry(entryId: string, lang: LanguageParam): Object {
		return this.contentfulService.getContentfulEntry(entryId, lang);
	}

	determineSite(): [Site, LanguageParam] {
		let host: string;
		if (window.location.hostname === 'localhost' && environment.envName === EnvType.UAT) {
			host = environment.uatDomain;
		} else {
			host = window.location.hostname;
		}

		return [UtilityFunctions.siteCheck.determineSite(
			host
		), this.currentLang];
	}

	// default locale set in json file
	determineLocale(site: Site, lang: LanguageParam = ''): string {
		return UtilityFunctions.siteCheck.determineLanguage(
			lang,
			site
		);
	}

	get siteEmulation(): Observable<[Site, LanguageParam]> {
		return this.eventStream;
	}

	getBrand(): Site {
		return this.currentBrand;
	}

	setBrand(brand: Site['alias']): void {
		this.currentBrand = UtilityFunctions.siteCheck.determineSiteByAlias(
			brand
		);
		this.streamBrandChange();
	}

	setLanguage(language: LanguageParam): void {
		this.currentLang = language;
		this.streamBrandChange();
	}

	streamBrandChange(): void {
		this.eventStream.next([this.currentBrand, this.currentLang]);
	}
}
