import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, forkJoin } from 'rxjs';

import { AuthenticationService } from '../../login/services/authentication.service';
import { Account } from '../models/account';
import { AccountService } from '../../shared/services/account.service';
import { LoyaltyDetails } from '../models/loyaltydetails';
import { PointsDetails } from '../models/pointsdetails';
import { PointsService } from '../../shared/services/points.service';
import { RewardsDetails } from '../models/rewardsdetails';
import { RewardsService } from '../../shared/services/rewards.service';
import { SegmentsDetails } from '../models/segmentsdetails';
import { SegmentsService } from '../../shared/services/segments.service';

// Logging
import { LogService } from 'ngx-log-service';

@Injectable()
export class LoyaltyService {
	constructor(public authenticationService: AuthenticationService,
		private accountService: AccountService,
		private logService: LogService,
        private pointsService: PointsService,
        private rewardsService: RewardsService,
		private segmentsService: SegmentsService) {

		this.logService.namespace = 'LoyaltyService';
	}

	private _loyalty: BehaviorSubject<LoyaltyDetails> = new BehaviorSubject<LoyaltyDetails>(null);

	private _loyaltyChecked = false;

	private _hasAccount = false;
	private _hasPoints = false;
	private _isInactive = false;

	public getLoyaltyData(): Observable<LoyaltyDetails> {
		return this._loyalty;
	}

	get loyaltyDetails(): Observable<LoyaltyDetails> {

		if (!this._loyaltyChecked) {
			forkJoin({
				AccountData: this.getAccountData(),
				PointsData: this.getPointsData(),
				RewardsData: this.getRewardsData(),
				SegmentsData: this.getSegmentsData()
			})
			.subscribe(resp => {
				this._loyalty.next(resp);

				if (resp.AccountData.AccountID) {
					this._hasAccount = true;
				}

				if (this._hasAccount && resp.AccountData.IsInactive){
					this._isInactive = true;
				}

				if(resp.PointsData && resp.PointsData.length > 0 && resp.PointsData[0].ProgramName) {
					this._hasPoints = true;
				}

				if(!this._loyaltyChecked && resp.AccountData.AccountID && !resp.AccountData.IsMadAccount) {
					this.accountService.patchIsMadAccountFlag(resp.AccountData.AccountID, true)
						.subscribe(result => {
							if (result) {
								this.logService.info(resp.AccountData.AccountID + ": Successfully set isMadAccount flag.");
							}
						});
				}

                this._loyaltyChecked = true;
			});
		}

		return this._loyalty;
	}

	private getAccountData(): Observable<Account> {
		return this.accountService.getExistingAccountWithId(this.authenticationService.user.AccountID);
	}

	private getPointsData(): Observable<Array<PointsDetails>> {
		return this.pointsService.getPointsData(this.authenticationService.user.AccountID);
    }

    private getRewardsData(): Observable<Array<RewardsDetails>> {
        return this.rewardsService.getRewardsData(this.authenticationService.user.AccountID);
    }

	private getSegmentsData(): Observable<Array<SegmentsDetails>> {
		return this.segmentsService.getSegmentsData(this.authenticationService.user.AccountID);
	}

	public hasAccount(): boolean {
		return this._hasAccount;
	}

	public hasPoints(): boolean {
		return this._hasPoints;
    }

    public isProcessing(): boolean {
        return !this._loyaltyChecked;
    }

	public isInactive(): boolean {
		return this._isInactive;
	}
}
