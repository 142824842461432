import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { map, catchError } from 'rxjs/operators';
import { Observable, ReplaySubject, throwError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../login/services/authentication.service';
import { ResponseData } from '../models/responsedata';
import { RewardsDetails } from '../models/rewardsdetails';
import { UtilityFunctions, RewardStatusCode } from '../../utility/utilityfunctions';
import moment, { Moment } from 'moment';

// Logging
import { LogService } from 'ngx-log-service';
import { ProgramId } from '../models/programIds.enum';


@Injectable()
export class RewardsService {
    constructor(private logService: LogService,
        private http: HttpClient,
        private authenticationService: AuthenticationService,
        private datePipe: DatePipe) {
        this.logService.namespace = 'RewardsService';
    }

    private apigatewayEndpoint = environment.apiEndpoint + '/loyalty-account-service/accounts';

    private _rewards: ReplaySubject<RewardsDetails> = new ReplaySubject<RewardsDetails>(1);
    private _rewardsChecked = false;

    private _activeRewards: number;

    public getRewardsData(accountId: string): Observable<Array<RewardsDetails>> {
        const rewardsLookupUrl = this.apigatewayEndpoint + '/' + accountId + '/awards';

        return this.http.get<ResponseData<any>>(rewardsLookupUrl, { headers: this.authenticationService.getSecuredHttpOptions() })
            .pipe(map(responseObject => this.mapRewardsObject(responseObject),
                catchError(err => this.returnErrorEvent(err))));
    }

    private mapRewardsObject(responseObject: any): Array<RewardsDetails> {
        let mappedReward: Array<RewardsDetails> = new Array<RewardsDetails>();

        this._activeRewards = 0;

        if (responseObject && responseObject.data && responseObject.data[0]) {
            responseObject.data.forEach(reward => {
                var rewardEntry = new RewardsDetails();
                rewardEntry.RewardId = reward.awardId;
                rewardEntry.ProgramId = reward.programId;
                rewardEntry.ProgramName = reward.programName;
                rewardEntry.EarnedDate = this.getJustDate(reward.earnedDate);
                rewardEntry.ExpiredDate = this.getJustDate(reward.expirationDate);
                rewardEntry.RedeemedDate = this.getJustDate(reward.redeemedDate);
                rewardEntry.Status = RewardStatusCode[reward.status];
                rewardEntry.StatusCode = reward.status;

                this.updateCount(reward.status);

                mappedReward.push(rewardEntry);
            });
        }

        return mappedReward;
    }

    private returnErrorEvent(error: HttpErrorResponse | any): Observable<never> {
        this.handleErrorLog(error);
        return throwError(error);
    }

    private handleErrorLog(error: HttpErrorResponse | any): void {
        const errMsg: string = error.message ? error.message : error.toString();
        this.logService.fatal('LAS rewards access has failed', { data: error, message: errMsg });
    }

    get rewards(): Observable<RewardsDetails> {
        if (!this._rewardsChecked) {
            this.getRewardsData(this.authenticationService.user.AccountID)
                .pipe(
                    catchError((err, caught) => {
                        this.handleErrorLog(err);

                        // Push Error onto ReplaySubject
                        this._rewards.error(err);
                        return throwError(err);
                    }))
                .subscribe(userRewards => {
                    if (userRewards[0]) {
                        this._rewards.next(userRewards[0]);
                    }
                    else {
                        return null;
                    }
                });
        }

        this._rewardsChecked = true;

        return this._rewards;
    }

	private getJustDate(inputDate: Date): string {
		let inDate = moment(inputDate);
        let stringDate = "";

		// NULL Redeemed Date is set to "01/01/10000"
		if (inDate.month() !== 12 && inDate.day() !== 31 && inDate.year() !== 9999) {
			stringDate = inDate.format('MM/DD/YYYY');
        }
        else {
            stringDate = "N/A";
        }

        return stringDate;
    }

    private updateCount(statusCode: RewardStatusCode) {
        if (statusCode === RewardStatusCode.Active) {
            this._activeRewards++;
        }
    }

    public getActiveRewardCount(): number {
        return this._activeRewards;
    }
}
