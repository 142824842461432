import { LanguageParam } from './shared/types/LanguageParam';
import { LoyaltyService } from './shared/services/loyalty.service';
import { BrandService } from './shared/services/brand.service';
import { Site, SiteAlias, UtilityFunctions } from './utility/utilityfunctions';
import { Unsubscribe } from './shared/base/unsubscribe';
import { AuthenticationService } from './login/services/authentication.service';
import { Router, NavigationEnd } from '@angular/router';

import { Component, OnInit, AfterViewInit, ViewEncapsulation, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { environment } from '../environments/environment';

const { siteCheck: { languageOverrideUrl, hideFundrive, determineLanguage, isCanadaBrand } } = UtilityFunctions;

const contentfulMainNav = 'mainNavigation';

declare var gtag: any;

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'customer-portal',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent extends Unsubscribe implements OnInit, AfterViewInit {
	currentBrand: Site;
	hamburgerOpened = false;
	urlOverride = '';
	currentLang: LanguageParam = '';
	isCanada: Boolean = false;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		public loyaltyService: LoyaltyService,
		private brandService: BrandService,
		private title: Title,
		private authenticationService: AuthenticationService,
		private router: Router) {
		super(new Subject<boolean>());
		this.authenticationService.handleAuthentication();
		this.currentBrand = this.brandService.getBrand();
	}

	ngAfterViewInit() {
		// Subscribe to changes from URL or DropDown
		this.brandService.siteEmulation.pipe(
			takeUntil(this.killSubscription))
			.subscribe(([brand, lang]) => {
				this.title.setTitle(this.determinePageTitle(brand));
				this.currentBrand = brand;
				this.currentLang = lang;
				this.setUrls();

				this.document.documentElement.lang = determineLanguage(lang, brand);

				this.isCanada = isCanadaBrand(this.currentBrand);

				const entryId = this.currentBrand.entryIds.filter(x => x.name === contentfulMainNav)[0].id;
				this.brandService.injectBrand(entryId, lang);
			});
	}

	ngOnInit() {
		this.router.events
			.subscribe(event => {
				if (event instanceof NavigationEnd) {
					if (typeof gtag !== 'undefined') {
						gtag('set', 'page_path', this.router.url);
						gtag('event', 'page_view');
					}
				}
			});
	}

	determinePageTitle(brand: Site): string {
		if (brand.alias === SiteAlias.VillageDesValeurs) {
			return 'Club des Super Aubaines';
		} else {
			return 'Super Savers Club';
		}
	}

	openHamburger(open: boolean) {
		this.hamburgerOpened = open;
	}

	setUrls() {
		this.urlOverride = languageOverrideUrl(this.currentLang, this.brandService.getBrand());
	}

	isLoggedIn() {
		return this.authenticationService.isLoggedIn();
	}

	logout() {
		this.hamburgerOpened = false;
		this.authenticationService.logout();
	}

	get hideFundrive(): boolean {
		return hideFundrive(this.currentBrand.alias, this.currentLang);
	}

	get isValueVillageBrand(): boolean {
		return this.currentBrand.alias === SiteAlias.ValueVillage;
	}

	hideAccountHome() {
		if (isCanadaBrand(this.currentBrand) && environment.featureFlags.flexpoints.toLowerCase() === 'true') {
			// For Canadians, Account Home should always be shown.
			return false;
		}
		
		if(this.loyaltyService.hasPoints()) {
			return false;
		}

		// No points and not Canada -> Hide
		return true;
	}

	isActive(linkPath: string) {
		return this.router.isActive(linkPath, false);
	}
}
