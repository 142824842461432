import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../login/services/authentication.service';
import { ResponseData } from '../models/responsedata';
import { Observable, ReplaySubject, throwError } from 'rxjs';

import { LogService } from 'ngx-log-service';
import { SegmentsDetails } from '../models/segmentsdetails';

@Injectable()
export class SegmentsService {
    constructor(private logService: LogService, private http: HttpClient, private authenticationService: AuthenticationService) {
        this.logService.namespace = 'SegmentsService';
    }

    private apigatewayEndpoint = environment.apiEndpoint + '/loyalty-account-service/accounts';

    private _segments: ReplaySubject<Array<SegmentsDetails>> = new ReplaySubject<Array<SegmentsDetails>>(1);
    private _segmentsChecked = false;

    public getSegmentsData(accountId: string): Observable<Array<SegmentsDetails>> {
        const segmentsLookupUrl = this.apigatewayEndpoint + '/' + accountId + '/segments';

        return this.http.get<ResponseData<any>>(segmentsLookupUrl, { headers: this.authenticationService.getSecuredHttpOptions() })
            .pipe(map(responseObject => this.mapSegmentsObject(responseObject),
                catchError(err => this.returnErrorEvent(err))));
    }

    private mapSegmentsObject(responseObject: any): Array<SegmentsDetails> {
        const segmentsData: Array<SegmentsDetails> = new Array<SegmentsDetails>();

        if(responseObject && responseObject.data && responseObject.data.length > 0) {
            responseObject.data.forEach(segments => {
                var segmentsEntry = new SegmentsDetails();
                segmentsEntry.SegmentId = segments.segmentId;
                segmentsEntry.SegmentName = segments.segmentName.trim();
                segmentsEntry.ExpiryDate = new Date(segments.expiryDate);

                segmentsData.push(segmentsEntry);
            });
        }

        return segmentsData;
    }

    private returnErrorEvent(error: HttpErrorResponse | any): Observable<never> {
        this.handleErrorLog(error);
        return throwError(error);
    }

    private handleErrorLog(error: HttpErrorResponse | any): void {
		const errMsg: string = error.message ? error.message : error.toString();
		this.logService.fatal('LAS points access has failed', { data: error, message: errMsg });
	}

    get segments(): Observable<Array<SegmentsDetails>> {
        if(!this._segmentsChecked) {
            this.getSegmentsData(this.authenticationService.user.AccountID)
                .pipe(
                    catchError((err) => {
                        this.handleErrorLog(err);

                        this._segments.error(err);
                        return throwError(err);
                    })
                )
                .subscribe(userSegments => {
                    var userHasSegments = userSegments.length > 0;
                    if(userHasSegments) {
                        this._segments.next(userSegments);
                    } else {
                        return null;
                    }
                });
        }

        this._segmentsChecked = true;

        return this._segments;
    }

    public segmentsChecked(): boolean {
        return this._segmentsChecked;
    }
}