import { LanguageParam } from './../../shared/types/LanguageParam';
import { Params } from '@angular/router';
// External dependencies
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';

// Authentication library
import * as auth0 from 'auth0-js';
import { JwtHelperService } from '@auth0/angular-jwt';

// Logging
import { LogService } from 'ngx-log-service';

// Custom dependencies
import { environment } from '../../../environments/environment';
import { User } from '../models/user';
import { UtilityFunctions, Site } from '../../utility/utilityfunctions';
import { Unsubscribe } from '../../shared/base/unsubscribe';
import { BrandService } from '../../shared/services/brand.service';
import { ResponseData } from '../../shared/models/responsedata';

// JWT Token handling library
const jwtHelper = new JwtHelperService();
const resendEmailVerificationEndpoint: string = environment.apiEndpoint + '/online-account-service/jobs/verification-email';

@Injectable()
export class AuthenticationService extends Unsubscribe {
	constructor(private logService: LogService,
		private router: Router,
		private brandService: BrandService,
		private http: HttpClient) {
		super(new Subject<boolean>());
		this.logService.namespace = 'LoginService';
		this.setParams();
		this.brandService.siteEmulation.pipe(
			takeUntil(this.killSubscription))
			.subscribe(([brand, lang]) => {
				this.currentBrand = brand;
				this.currentLang = lang;
			});

		this.brandService.routeParamsChange$.subscribe((params: Params) => {
			this.currentLang = params['lang'] || '';
		});
	}

	public get token(): string {
		return localStorage.getItem('id_token') || '';
	}

	get user(): User {
		if (UtilityFunctions.utilities.objectIsNewOrEmpty(this._user) || !this._user.AccountID) {
			this.extractTokenDetails();
		}

		return this._user;
	}

	set user(user: User) {
		this._user = user;
	}

	private _user: User = new User();
	private handleAuthCallbackCompleteSubject$ = new ReplaySubject<boolean>(1);
	public currentLang: LanguageParam = '';
	public currentBrand: Site;
	public emailVerified = true;

	// Local instantiation of Auth 0 library
	private auth0() {
		return new auth0.WebAuth(
		{
			clientID: environment.auth0_clientId,
			domain: environment.auth0_domain,
			responseType: 'token id_token',
			audience: environment.auth0_audience,
			redirectUri: UtilityFunctions.siteCheck.determineRedirectUri(
				window.location.protocol,
				window.location.hostname,
				window.location.port,
				this.currentLang
				),
			scope: 'openid'
		});
	}

	// Set UTM Params
	private setParams(): void {
		const params = UtilityFunctions.utilities.gatherParams(window.location.search);

		if (params.has('utm_campaign') || params.has('utm_content') || params.has('utm_medium') || params.has('utm_source')) {
			this._user.Utm_Campaign = params.get('utm_campaign');
			this._user.Utm_Content = params.get('utm_content');
			this._user.Utm_Medium = params.get('utm_medium');
			this._user.Utm_Source = params.get('utm_source');
		}

		this._user.IsSupportSignup = params.has('supportSignUp') ? (params.get('supportSignUp') === 'true') : false;
	}

	public getAuthCallback(): Observable<boolean> {
		return this.handleAuthCallbackCompleteSubject$.asObservable();
	}

	public login(focus: string): void {
		this.auth0().authorize({
			ContentfulAuthToken: environment.contentfulAuthToken,
			ContentfulSpaceId: environment.contentfulSpaceId,
			ContentfulUri: environment.contentfulUri,
			AppLanguage: this.currentLang,
			BrandAlias: this.currentBrand.alias,
			Focus: focus,
			Utm_Campaign: this.user.Utm_Campaign,
			Utm_Content: this.user.Utm_Content,
			Utm_Medium: this.user.Utm_Medium,
			Utm_Source: this.user.Utm_Source
		});
	}

	public logout(): void {
		this.clearSession();

		this._user = new User();

		// Navigate back to login and keep current language in URL, if specified
		let loginUrl = '/login' + UtilityFunctions.siteCheck.languageOverrideUrl(this.currentLang, this.brandService.getBrand());
		this.router.navigate([loginUrl]);
	}

	public isLoggedIn(): boolean {
		// Check whether the current time is past the Access Token's expiry time
		const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
		return new Date().getTime() < expiresAt;
	}

	public resendEmailVerification(): Observable<boolean> {
		const postRequest = {
			user_id: this._user.ObjectID,
			client_id: environment.auth0_clientId
		};

		const headers: HttpHeaders = new HttpHeaders({ 'Ocp-Apim-Subscription-Key': environment.apiPublicSubscriptionKey });
		return this.http.post<ResponseData<any>>(resendEmailVerificationEndpoint, postRequest, { headers: headers, observe: 'response' })
			.pipe(map(response => response.status === 201));
	}

	private clearSession(): void {
		// Remove tokens and expiry time from localStorage
		localStorage.removeItem('access_token');
		localStorage.removeItem('id_token');
		localStorage.removeItem('expires_at');
	}

	private tokenIsExpired(): boolean {
		const expiresAt = JSON.parse(localStorage.getItem('expires_at'));

		return !expiresAt ? true : new Date().getTime() > expiresAt;
	}

	private resetSession(): void {
		this.clearSession();
		this.navigateToDashboard();
	}

	// Worker Methods

	// Get token from local storage or refresh if expired, if that fails, send to login
	public extractTokenDetails(): void {
		if (localStorage && localStorage.id_token) {
			if (!this.tokenIsExpired()) {
				this.setUserValues(this.extractUserData(localStorage.id_token));
			} else {
				this.resetSession();
			}
		} else if (this.isLoggedIn()) {
			this.auth0().checkSession({},
				(err, result) => {
					if (err) {
						this.logService.fatal('Fatal error renewing session token', err);
						this.resetSession();
					} else {
						this.setSession(result);
					}
				});
		}
	}

	public handleAuthentication(): void {
		const lang = UtilityFunctions.siteCheck.getLangFromPath(window.location.pathname);
		sessionStorage.setItem('lang', lang);
		this.auth0().parseHash((err, authResult) => {
			if (authResult && authResult.accessToken && authResult.idToken) {
				window.location.hash = '';
				this.clearSession();
				this.setSession(authResult);
				this.setUserValues(this.extractUserData(authResult.idToken));
				this.handleAuthCallbackCompleteSubject$.next(true);
			} else if (err) {
				if (err.error && err.errorDescription) {
					const description = err.errorDescription.split('-');
					// auth0 actions will return "access_denied" when returning api.access.deny, and is not customizable,
					// vs. the old rules which let us assign "unauthorized" as the error message. This 'or' statement below
					// lets us handle the rule or the action while we decommission rules, then we can remove the "unauthorized"
					// option once migration is complete
					if ((err.error === 'access_denied' || err.error === 'unauthorized') && description[2] === 'Unverified') {
						this._user.ObjectID = description[1];
						this.emailVerified = false;
					} else {
						this.logService.fatal('Auth0 threw a fatal error: ', err);
					}
				} else {
					this.logService.fatal('Auth0 threw a fatal error: ', err);
				}

				this.clearSession();

				this.handleAuthCallbackCompleteSubject$.next(true);
			} else if (this._user.IsSupportSignup) {
				this.clearSession();
				this.handleAuthCallbackCompleteSubject$.next(true);
			} else {
				this.handleAuthCallbackCompleteSubject$.next(true);
			}
		});
	}

	public getSecuredHttpOptions(existingHeader?: HttpHeaders): HttpHeaders {
		const name = 'Authorization';
		const value = 'Bearer ' + this.token;

		const currentHeaders: HttpHeaders = existingHeader || new HttpHeaders();
		const securedHeader: HttpHeaders = currentHeaders.set(name, value);
		return securedHeader;
	}

	public getDoubleSecuredHttpOptions(existingHeader?: HttpHeaders): HttpHeaders {
		const name = 'Authorization';
		const value = 'Bearer ' + this.token;

		const currentHeaders: HttpHeaders = existingHeader || new HttpHeaders();
		const securedHeader: HttpHeaders = currentHeaders.set(name, value);
		const doubleSecuredHeader = securedHeader.set('Ocp-Apim-Subscription-Key', environment.apiPublicSubscriptionKey);

		return doubleSecuredHeader;
	}

	private setSession(authResult): void {
		// Set the time that the Access Token will expire at
		const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
		localStorage.setItem('access_token', authResult.accessToken);
		localStorage.setItem('id_token', authResult.idToken);
		localStorage.setItem('expires_at', expiresAt);
	}

	private extractUserData(token: string): object {
		return jwtHelper.decodeToken(token);
	}

	private setUserValues(tokenDecryption): void {
		if (tokenDecryption) {
			this._user.ObjectID = tokenDecryption.sub;
			const loginsCount = tokenDecryption['https://savers.com/logins_count'];
			this._user.AccountID = tokenDecryption['https://savers.com/accountId'] || '';
			this._user.IsSocialLogin = tokenDecryption['https://savers.com/isSocial'];
			this._user.FirstTimeSignup = loginsCount === 1;
			this._user.LoginEmail = tokenDecryption['https://savers.com/login_email'];
		}
	}

	private navigateToDashboard(): void {
		// Navigate back to login and keep current language in URL, if specified
		const accountHome = '/accounthome' + UtilityFunctions.siteCheck.languageOverrideUrl(this.currentLang, this.brandService.getBrand());
		this.router.navigate([accountHome]);
	}
}
