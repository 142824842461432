import { LanguageParam } from './../types/LanguageParam';
import { UtilityFunctions } from '../../utility/utilityfunctions';
import { LanguageOption } from './language.enum';

const {
	conversions: { convertMilesToKilos },
	siteCheck: { isLanguageOption }
} = UtilityFunctions;

export class Store {

	private static readonly villageDesValeursBrand = 'Village des Valeurs';
	public Number: number;
	public Name: string;
	public Address1: string;
	public Address2: string;
	public SuiteNumber: string;
	public City: string;
	public Region: string;
	public Country: string;
	public PostalCode: string;
	public Latitude: number;
	public Longitude: number;
	public Distance: number;
	public Brand: string;
	public Phone: string;
	public userSelected = false;
	public brandTag = '-thrift-store-';
	public dirUrl = 'https://maps.google.com?daddr=';
	public disAndUnits: string;
	public showOn: Array<string>;
	public storeOpenDate: string;
	public isTemporarilyClosed: boolean;

	public getDistanceAndUnits(miles: boolean): string {
		if (this.Distance) {
			this.disAndUnits = this.Distance.toFixed(1).toString() + ' mi';

			if (!miles) {
				this.disAndUnits = convertMilesToKilos(this.Distance).toFixed(1).toString() + ' km';
			}
		}

		return this.disAndUnits;
	}

	public dirURL(): string {
		// If user came from Village Des Valeurs, use French Google Map
		if (this.Brand && this.Brand === Store.villageDesValeursBrand) {
			this.dirUrl = 'https://maps.google.com?hl=fr&daddr=';
		}

		this.dirUrl += '' + this.Address1 + '+' + this.City + '+' + this.Region + '+' + this.PostalCode;

		return this.dirUrl;
	}

	public storeURL(currentLang: LanguageParam = ''): string {
		let sUrl = 'https://stores.savers.com/';

		// If user came from Village Des Valeurs, use French Savers Store locator
		if (this.Brand && this.Brand === Store.villageDesValeursBrand) {
			sUrl = 'https://magasinage.villagedesvaleurs.com/';
			this.brandTag = '-magasin-doccasions-';
		}

		let langAppend = '';

		// the store page we link to here doesn't work with en or fr in the URL
		// if fr, it should also be brand VDV which is a separate site mentioned above, which doesn't use a language option in the url
		// so really this logic is just here for Spanish ('es')
		if (isLanguageOption(currentLang) && currentLang !== LanguageOption.Fr && currentLang !== LanguageOption.En) {
			langAppend = currentLang ? `${currentLang}/` : '';
		}

		if (this.Region && this.City && this.Brand) {
			sUrl += langAppend + this.Region.toLowerCase() +
				'/' +
				this.City.toLowerCase().replace('é', 'e') +
				'/' +
				this.Brand.toLowerCase().replace(/\s/g, '') +
				this.brandTag +
				this.Number.toString() +
				'.html';
		}

		return sUrl;
	}
}
