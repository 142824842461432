import { EnvType } from './env.enum';
// This file contains the UAT environment variables and will overwrite the default values in environment.ts
// The build system defaults to the DEV environment,
// to access the UAT build use 'ng build --env=uat' then environment.uat.ts will be used instead
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	production: false,
	envName: EnvType.UAT,
	contentfulSpaceId: 'pjufol7taoxm',
	contentfulAuthToken: 'df7040f83023b73a514015d8c37b06f0c7e719f5708f0257242e459e08e395ae',
	contentfulUri: 'https://preview.contentful.com',
	apiEndpoint: 'https://saversapiuat.azure-api.net',
	apiPublicSubscriptionKey: '5a0e4d99eca54b08b79b4ba4cb6dd9b3',
	app_insights_key: 'c6666050-29a2-4b05-90fd-0f4a73ea2668',
	auth0_clientId: 'wDpD3aFO3Z6yE_Dioh53Zucv5LTksczU',
	auth0_audience: 'https://savers-uat.auth0.com/userinfo',
	auth0_domain: 'auth.uat.savers.com',
	// UAT domains
	uatDomain: 'sscportal.uat.savers.com',
	featureFlags: {
		// Replaced by Azure Pipeline.
		// For local dev, change this to true/false
		flexpoints: 'true',
		disableSpanishLanguageOption: 'false'
	},
	googleTracking: {
		savers: {
			gtagId: 'G-WPK2Q37Q48',
			gtmId: 'GTM-5VS577T'
		},
		valueVillage: {
			gtagId: 'G-Z2DRRWYXH7',
			gtmId: 'GTM-T73ZSDC'
		},
		valueVillageCA: {
			gtagId: 'G-XW68BLQQVH',
			gtmId: 'GTM-TQ2DJ9S'
		},
		villageDesValeurs: {
			gtagId: 'G-6K1Q0N0SC0',
			gtmId: 'GTM-5TFKHJJ'
		}
	},
	termlyCookieManagement: {
		savers: {
			termlyId: '921e7644-34e4-4ecc-81d9-f770c562aa11'
		},
		valueVillage: {
			termlyId: 'ce6d12f3-3a4d-424a-80fc-e484f4d22243'
		},
		valueVillageCA: {
			termlyId: '7a46d86f-ef2f-4548-8b02-e82216430966'
		},
		villageDesValeurs: {
			termlyId: 'ed394d46-895b-4c3f-aaa9-29b48c064fb5'
		}
	}
};
