import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { Observable, ReplaySubject, throwError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { AuthenticationService } from '../../login/services/authentication.service';
import { ResponseData } from '../models/responsedata';

// Logging
import { LogService } from 'ngx-log-service';
import { PointsDetails } from '../models/pointsdetails';
import { ProgramId } from '../models/programIds.enum';

@Injectable()
export class PointsService {
	constructor(private logService: LogService,
		private http: HttpClient,
		private authenticationService: AuthenticationService) {
		this.logService.namespace = 'PointsService';
	}

	private apigatewayEndpoint = environment.apiEndpoint + '/loyalty-account-service/accounts';

	private _points: ReplaySubject<Array<PointsDetails>> = new ReplaySubject<Array<PointsDetails>>(1);
	private _pointsChecked = false;
	private _hasProgram = false;

	public getPointsData(accountId: string): Observable<Array<PointsDetails>> {
		const pointsLookupUrl = this.apigatewayEndpoint + '/' + accountId + '/points';

		return this.http.get<ResponseData<any>>(pointsLookupUrl, { headers: this.authenticationService.getSecuredHttpOptions() })
			.pipe(map(responseObject => this.mapPointsObject(responseObject),
				catchError(err => this.returnErrorEvent(err))));
	}

	private mapPointsObject(responseObject: any): Array<PointsDetails> {
        const pointsData: Array<PointsDetails> = new Array<PointsDetails>();

		if (responseObject && responseObject.data && responseObject.data[0]) {
			responseObject.data.forEach(points => {
				var pointsEntry = new PointsDetails();
				pointsEntry.ProgramId = points.programId;
				pointsEntry.ProgramName = points.programName;
				pointsEntry.Points = points.currentPoints;
				pointsEntry.Delta = this.calculateDelta(points);
				pointsEntry.TotalAcquiredPoints = points.totalAcquiredPoints;

				pointsData.push(pointsEntry);
			});
		}

		return pointsData;
	}

	private returnErrorEvent(error: HttpErrorResponse | any): Observable<never> {
		this.handleErrorLog(error);
		return throwError(error);
	}

	private handleErrorLog(error: HttpErrorResponse | any): void {
		const errMsg: string = error.message ? error.message : error.toString();
		this.logService.fatal('LAS points access has failed', { data: error, message: errMsg });
	}

	get points(): Observable<Array<PointsDetails>> {
		if (!this._pointsChecked) {
			this.getPointsData(this.authenticationService.user.AccountID)
				.pipe(
					catchError((err, caught) => {
						this._hasProgram = false;
						this.handleErrorLog(err);

						// Push Error onto ReplaySubject
						this._points.error(err);
						return throwError(err);
					}))
				.subscribe(userPoints => {
					var userHasPoints = userPoints.length > 0;
					if(userHasPoints) {
						this._points.next(userPoints);
						this._hasProgram = true;
					} else {
						return null;
					}
				});
		}

		this._pointsChecked = true;

		return this._points;
	}

	public pointsChecked(): boolean {
		return this._pointsChecked;
	}

	public hasPoints(): boolean {
		return this._hasProgram;
    }

    private calculateDelta(pointsInfo: any): number {
		if(pointsInfo.programId == ProgramId.CanadaFlexPointsDenimSuedeTracking) {
			// Flexpoints Programs
			return this.calculateCanadaDelta(pointsInfo);
		} else {
			// All other Points Programs
			return this.calculateUSDelta(pointsInfo);
		}
    }

	private calculateUSDelta(pointsInfo: any): number {
		let delta: number = 0;

		var points = pointsInfo.currentPoints;
		var requiredPoints = pointsInfo.requiredPoints;

		// Negative Points: Next Reward Delta = abs(negative points) + required Points				example {points=-86,requiredPoints=100} Delta = 86 + 100
        if (points < 0) {
            delta = Math.abs(points) + requiredPoints;
        }
        // Greater Points: Next Reward Delta = required Points - points after last reward			example {points=233,requiredPoints=100} Delta = 100 - 33
        // Normal Points:  Next Reward Delta = required Points - points								example {points=83,requiredPoints=100} Delta = 100 - 83
        else {
            let tempPoints = points;

            while (tempPoints >= requiredPoints) {
                tempPoints -= requiredPoints;
            }

            delta = requiredPoints - tempPoints;
        }

        return delta;
	}

	private calculateCanadaDelta(pointsInfo: any): number {
		var points = pointsInfo.totalAcquiredPoints;
		var requiredPoints = 500;

		var canadaDelta = requiredPoints - points;

		return canadaDelta > 0 ? canadaDelta : 0;
	}
}
