import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import './assets/js/contentful-integration';
import { environment } from './environments/environment';
import { TrackingService } from './app/shared/services/tracking.service';
import { CookieService } from './app/shared/services/cookie.service';
import { UtilityFunctions } from './app/utility/utilityfunctions';


if (environment.production) {
    enableProdMode();
}

declare var contentful: any;

bootstrapApp();

function bootstrapApp() {
    contentful.config({
        AuthToken: environment.contentfulAuthToken,
        SpaceId: environment.contentfulSpaceId,
        Uri: environment.contentfulUri
	});

    platformBrowserDynamic().bootstrapModule(AppModule);
	
	TrackingService.setupGoogleTracking();
}