import { LanguageOption } from './../shared/models/language.enum';
import { LanguageParam } from './../shared/types/LanguageParam';
import { Account } from '../shared/models/account';
import { URLSearchParams } from '@angular/http';
import SiteMappings from '../../assets/site-mappings.json';

export enum LanguageCode {
	English = 405240000,
	French = 405240001,
	Spanish = 405240002
}

export enum SiteAlias {
	Savers = 'SAV',
	ValueVillage = 'VV',
	ValueVillageCA = 'VVCA',
	VillageDesValeurs = 'VDV',
	SaversAU = 'SAU'
}

export enum Locale {
	English = 'en',
	EnglishUS = 'en-US',
	EnglishAU = 'en-AU',
	French = 'fr-CA',
	Spanish = 'es'
}

export enum NavMenuMode {
	Side = 'side',
	Over = 'over'
}

export enum RewardStatusCode {
	Active,
	Expired,
	Redeemed
}

export const languageOptions = new Set<string>(Object.values(LanguageOption));

export class UtilityFunctions {
	static conversions = class {
		static convertKiloToMiles(distance: number) {
			distance = distance / 1.60934;

			return distance;
		}

		static convertMilesToKilos(distance: number) {
			distance = distance * 1.60934;

			return distance;
		}
	};

	static utilities = class {
		static objectIsNewOrEmpty(obj): boolean {
			return Object.keys(obj).length <= 0;
		}

		static accountIsValid(accountObj: Account): boolean {
			if (accountObj.AccountID && accountObj.AccountID.trim()) {
				return true;
			} else {
				return false;
			}
		}

		static gatherParams(search: string): URLSearchParams {
			return new URLSearchParams(search.slice(1));
		}

		static isDateInFuture(todayDate: Date, compareDate: string): boolean {
			if (compareDate) {
				const openDate = new Date(compareDate);

				return openDate > todayDate;
			}

			return false;
        }

        static compare(a: number | string, b: number | string, isAsc: boolean) {
            return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
        }

        static isValidDate(d: Date) {
            return d instanceof Date && !isNaN(d.getTime());
        }
	};

	static siteCheck = class {
		static determineSite(domain: string): Site {
			domain = domain.toLowerCase();
			let site = Sites.mappings.filter(s => s.url === domain || s.vanityUrls.includes(domain))[0];

			if (site == null) {
				site = this.getDefaultBrand();
			}
			return site;
		}

		static determineSiteByAlias(siteAlias: string): Site {
			return Sites.mappings.filter(s => s.alias === siteAlias)[0];
		}

		static determineRedirectUri(
			protocol: string,
			hostName: string,
			port: string,
			lang: LanguageParam = ''
		): string {
			const langAppend = lang === '' ? '' : `/${lang}`;
			const redirectUri = `${protocol}//${hostName}`;

			return hostName === 'localhost' ?
				`${redirectUri}:${port}${langAppend}/` :
				`${redirectUri}${langAppend}/`;
		}

		static getLangFromPath(path: string): string {
			if (!path.endsWith('/')) {
				path = path + '/';
			}

			if (path.includes('/fr/')) {
				return LanguageOption.Fr;
			} else if (path.includes('/es/')) {
				return LanguageOption.Es;
			} else if (path.includes('/en/')) {
				return LanguageOption.En;
			} else {
				return '';
			}
		}

		static isLanguageOption(lang: string): boolean {
			return languageOptions.has(lang);
		}

		static determineDateFormat(site: Site): string[] {
			switch (site.dateFormat) {
				case "USFormats":
				default:
					return DateFormats.USFormats;
				case "NonUSFormats":
					return DateFormats.NonUSFormats;
			}
		}

		static getDefaultBrand(): Site {
			return Sites.mappings.filter(s => s.alias === SiteAlias.Savers)[0];
		}

		static brandOverride(alias: string): Site {
			return Sites.mappings.filter(s => s.alias === alias)[0];
		}

		static determineLanguage(lang: LanguageParam = '', site: Site): string {
			if (lang === LanguageOption.Fr &&
				site.locale.some(x => x.startsWith(LanguageOption.Fr))
			) {
				return Locale.French;
			} else if (lang === LanguageOption.Es &&
				site.locale.some(x => x.startsWith(LanguageOption.Es))
			) {
				return Locale.Spanish;
			} else if (lang === LanguageOption.En &&
				site.locale.some(x => x.startsWith(LanguageOption.En))
			) {
				return site.locale.filter((x) => x.startsWith(LanguageOption.En))[0];
			} else {
				return site.locale[0];
			}
		}

		static determineDefaultLanguage(site: Site): string {
			switch (site.name.toLowerCase()) {
				case 'vdv':
				case 'villagedesvaleurs':
					return "fr";
				default:
					return "en";
			}
		}

		static languageOverrideUrl(lang: LanguageParam, site: Site): string {
			if (lang === LanguageOption.Fr &&
				site.locale.some(x => x.startsWith(LanguageOption.Fr))
			) {
				return `/${LanguageOption.Fr}/`;
			} else if (lang === LanguageOption.Es &&
				site.locale.some(x => x.startsWith(LanguageOption.Es))
			) {
				return `/${LanguageOption.Es}/`;
			} else if (lang === LanguageOption.En &&
				site.locale.some(x => x.startsWith(LanguageOption.En))
			) {
				return `/${LanguageOption.En}/`;
			} else {
				return '';
			}
		}

		static getHelpUrl(lang: LanguageParam, site: Site): string {
			const currentPath = UtilityFunctions.siteCheck.determineLanguage(lang, site) === Locale.French ? '/aide/nous-joindre' : '/help/contact';

			return 'https://' + site.returnUrl + currentPath;
		}

		static getTermlyId(site: Site): string {
			if(!site) {
				return "";
			}

			var siteInfo = Sites.mappings.filter(x => x.alias == site.alias);
			if(!siteInfo) {
				return "";
			}

			return siteInfo[0].termlyId;
		}

		static containsLocale(alias: Site['alias'], lang: LanguageParam): boolean {
			return Sites.mappings.find(brand => {
				return brand.alias === alias;
			}).locale.some(x => x.startsWith(lang));
		}

		static hideFundrive(alias: Site['alias'], lang: LanguageParam): boolean {
			return (lang === LanguageOption.Fr &&
				alias === SiteAlias.ValueVillageCA) ||
			((lang === LanguageOption.Fr || lang === '') &&
				alias === SiteAlias.VillageDesValeurs) ||
			(alias === SiteAlias.SaversAU);
		}

		static isUSBrand(brand: Site): boolean {
			return brand.alias === SiteAlias.ValueVillage ||
			brand.alias === SiteAlias.Savers;
		}

		static isCanadaBrand(brand: Site): boolean {
			return brand.alias === SiteAlias.ValueVillageCA || 
				brand.alias === SiteAlias.VillageDesValeurs;
		}
	};
}

export class DateFormats {
	static readonly USFormats = ['MM/DD/YYYY', 'M/D/YYYY', 'MM/D/YYYY', 'M/DD/YYYY', 'MM/DD/YY', 'M/D/YY', 'MM/D/YY', 'M/DD/YY'];
	static readonly NonUSFormats = ['DD/MM/YYYY', 'D/M/YYYY', 'DD/M/YYYY', 'D/MM/YYYY', 'DD/MM/YY', 'D/M/YY', 'DD/M/YY', 'D/MM/YY'];
}

export interface Site {
	name: string;
	url: string;
	returnUrl: string;
	locale: string[];
	alias: string;
	dateFormat: string;
	entryIds: Array<EntryId>;
	vanityUrls: string[];
	termlyId: string;
}

export interface EntryId<idType = string> {
	name: string;
	id: idType;
}

export class Sites  {
	static readonly mappings = SiteMappings as Site[];
}

class VanityUrls {
	static readonly vvAltUrl01 = 'joinsaversclub.com';
	static readonly vvAltUrl02 = 'www.joinsaversclub.com';
	static readonly vvAltUrl03 = 'jointhesaversclub.com';
	static readonly vvAltUrl04 = 'www.jointhesaversclub.com';
	static readonly vDvAltUrl01 = 'clubdessuperaubaines.com';
	static readonly vDvAltUrl02 = 'www.clubdessuperaubaines.com';
	static readonly vDvAltUrl03 = 'adherezauclubdessuperaubaines.com';
	static readonly vDvAltUrl04 = 'www.adherezauclubdessuperaubaines.com';
	static readonly vDvAltUrl05 = 'adherezauclubdesaubaines.com';
	static readonly vDvAltUrl06 = 'www.adherezauclubdesaubaines.com';
}
