// Framework Modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { MatPaginatorModule } from '@angular/material';

// Environments
import { environment } from '../environments/environment';
import { EnvType } from '../environments/env.enum';

// Custom Modules
import { SharedModule } from './shared/modules/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { ProgramGuard } from './shared/guards/program.guard';
import { AuthenticationService } from './login/services/authentication.service';

// Custom Components
import { AppComponent } from './app.component';
import { NotifyBarComponent } from './shared/notify-bar.component';
import { HeaderComponent } from './shared/header.component';
import { FooterComponent } from './shared/footer.component';
import { PageNotFoundComponent } from './dashboard/pagenotfound/page-not-found.component';

// Third Party Components
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { Angulartics2Module } from 'angulartics2';

// Custom Services
import { ContentfulService } from './shared/services/contentful.service';
import { BrandService } from './shared/services/brand.service';
import { TrackingService } from './shared/services/tracking.service';
import { BrandEmulationService } from './shared/services/brand-emulation.service';
import { AccountService } from './shared/services/account.service';
import { PointsService } from './shared/services/points.service';
import { LoyaltyService } from './shared/services/loyalty.service';
import { RewardsService } from './shared/services/rewards.service';
import { SegmentsService } from './shared/services/segments.service';

// Logger
import { LogService, LogModule, LOG_LISTENER } from 'ngx-log-service';
import { LoggerListener } from './shared/listeners/logger.listener';
import { AppInsightsListener } from './shared/listeners/app-insights.listener';

@NgModule({
	imports: [
		BrowserModule,
        HttpClientModule,
        RouterModule.forRoot([]),
        BrowserAnimationsModule,
		FormsModule,
		NgxLoadingModule.forRoot({
			animationType: ngxLoadingAnimationTypes.circleSwish,
			backdropBackgroundColour: 'rgba(255,255,255,0.8)',
			backdropBorderRadius: '8px',
			fullScreenBackdrop: true,
			primaryColour: '#DA291C',
			secondaryColour: '#c93b27',
			tertiaryColour: '#c1756a'
		}),
		LogModule,
		Angulartics2Module.forRoot(),
        AppRoutingModule,
        MatPaginatorModule,
		SharedModule
	],
	declarations: [
		AppComponent,
		HeaderComponent,
		FooterComponent,
		PageNotFoundComponent,
		NotifyBarComponent,
	],
	providers: [
		ContentfulService,
		LogService,
		AppInsightsListener,
		{ provide: LOG_LISTENER, useClass: LoggerListener, multi: true },
		{ provide: BrandService, useClass: environment.production || environment.envName === EnvType.UAT ? BrandService : BrandEmulationService},
		TrackingService,
		AuthenticationService,
		ProgramGuard,
		AccountService,
		PointsService,
        LoyaltyService,
        RewardsService,
		SegmentsService,
		DatePipe
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
