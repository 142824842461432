import { Injectable } from '@angular/core';
import '../../../assets/js/contentful-integration.js';

declare var contentful: any;

@Injectable()
export class ContentfulService {
    injectContent(entryId: string, locale: string) {
		contentful.injectContent(entryId, locale);
    }

    getContentfulEntry(entryId: string, locale: string) {

      if(!locale) {
        locale = 'en';
      }

      var contentfulLocale = 'en';
      switch(locale.toLowerCase()) {
        case 'en':
          contentfulLocale = 'en';
          break;
        case 'fr':
          contentfulLocale = 'fr-CA';
          break;
        case 'es':
          contentfulLocale = 'es';
          break;
        default:
          contentfulLocale = 'en';
      }

      return contentful.getContentfulEntry(entryId, contentfulLocale);
    }
}
