import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './dashboard/pagenotfound/page-not-found.component';

const appRoutes: Routes = [
	{ path: 'login', loadChildren: './login/login.module#LoginModule' },
	{ path: '', loadChildren: './dashboard/dashboard.module#DashboardModule' },
	{ path: 'dashboard', loadChildren: './dashboard/dashboard.module#DashboardModule' },
	{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
	imports: [RouterModule.forRoot(appRoutes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
