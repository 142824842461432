import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { Observable, ReplaySubject, throwError, of } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Store } from '../../shared/models/store';
import { AuthenticationService } from '../../login/services/authentication.service';
import { Account } from '../models/account';
import { ResponseData } from '../models/responsedata';
import { UtilityFunctions } from '../../utility/utilityfunctions';

// Logging
import { LogService } from 'ngx-log-service';

@Injectable()
export class AccountService {
	constructor(private logService: LogService,
		private http: HttpClient,
		private authenticationService: AuthenticationService) {
		this.logService.namespace = 'AccountService';
	}

	private accountEndpoint = environment.apiEndpoint + '/loyalty-account-service/accounts';
	private onlineUserEndpoint = environment.apiEndpoint + '/online-account-service/users';

	private _account: ReplaySubject<Account> = new ReplaySubject<Account>(1);
	private _valueSet = false;

	public patchUserPassword(userObjectID: string, newPassword: string): Observable<boolean> {
		const passwordPatchUrl = this.onlineUserEndpoint + '/' + userObjectID;

		const mapFieldsForPatch = {
			password: newPassword,
			connection: 'Username-Password-Authentication'
		};

		return this.http.patch<ResponseData<any>>(passwordPatchUrl, mapFieldsForPatch, { headers: this.authenticationService.getDoubleSecuredHttpOptions(), observe: 'response' })
			.pipe(map(response => response.status === 200),
				catchError(err => this.returnErrorEvent(err)));
	}

	public patchUserEmail(userObjectID: string, newEmail: string): Observable<boolean> {
		const emailPatchUrl = this.onlineUserEndpoint + '/' + userObjectID;

		const mapFieldsForPatch = {
			email: newEmail,
			connection: 'Username-Password-Authentication'
		};

		return this.http.patch<ResponseData<any>>(emailPatchUrl, mapFieldsForPatch, { headers: this.authenticationService.getDoubleSecuredHttpOptions(), observe: 'response' })
			.pipe(map(response => response.status === 200),
				catchError(err => this.returnErrorEvent(err)));
	}

	public patchIsMadAccountFlag(userObjectID: string, isMadAccount: boolean): Observable<boolean> {
		const isMadPatchUrl = this.accountEndpoint + '/' + userObjectID;

		const patchRequest = {
			isMadAccount: isMadAccount
		};

		return this.http.patch<ResponseData<any>>(isMadPatchUrl, patchRequest, { headers: this.authenticationService.getSecuredHttpOptions(), observe: 'response' })
			.pipe(map(response => response.status === 204),
				catchError(err => this.returnErrorEvent(err)));
	}

	public getExistingAccountWithId(accountId: string): Observable<Account> {
		const accountIdLookupUrl = this.accountEndpoint + '/' + accountId + '?includeInactive=true';

		return this.http.get<ResponseData<any>>(accountIdLookupUrl, { headers: this.authenticationService.getSecuredHttpOptions() })
			.pipe(map(responseObject => this.mapAccountObject(responseObject),
				catchError(err => this.returnErrorEvent(err))));
	}

	public updateLoyaltyAccountWithContactDetails(accountObject: Account): Observable<number> {
		const patchEndpoint = this.accountEndpoint + '/' + accountObject.AccountID;
		const patchRequest = {};

		const mapFieldsForSave = {
			first_name: accountObject.FirstName,
			last_name: accountObject.LastName,
			mobile_phone: accountObject.MobileNumber || null,
			phone: accountObject.PhoneNumber || null,
			email: accountObject.CommunicationEmail || null,
			allow_emails: accountObject.SendEmails,
			date_of_birth: accountObject.DateOfBirth,
			isMadAccount: true,
			address_1: {
				line1: accountObject.Address1,
				line2: accountObject.Address2,
				city: accountObject.City,
				state: accountObject.State,
				postal_code: accountObject.PostalCode,
				country: accountObject.Country
			},
			preferred_store: accountObject.PreferredStore.Number,
			language_code: accountObject.Language
		};

		Object.keys(mapFieldsForSave).forEach(x => {
			if (mapFieldsForSave[x] !== null) {
				patchRequest[x] = mapFieldsForSave[x];
			}
		});

		return this.http.patch<ResponseData<any>>(patchEndpoint, patchRequest, { headers: this.authenticationService.getSecuredHttpOptions(), observe: 'response' })
			.pipe(map(response => response.status),
				catchError(err => this.handleNotFoundOrConflict(err)));
	}

	private mapAccountObject(responseObject: any): Account {
		const mappedAccount: Account = new Account();

		if (responseObject && responseObject.data) {
			if (!UtilityFunctions.utilities.objectIsNewOrEmpty(responseObject) &&
				!UtilityFunctions.utilities.objectIsNewOrEmpty(responseObject.data)) {

				mappedAccount.PreferredStore = new Store();

				mappedAccount.AccountID = responseObject.data.accountid;
				mappedAccount.FirstName = responseObject.data.first_name;
				mappedAccount.LastName = responseObject.data.last_name;

				if (responseObject.data.address_1) {
					mappedAccount.Address1 = responseObject.data.address_1.line1;
					mappedAccount.Address2 = responseObject.data.address_1.line2;
					mappedAccount.City = responseObject.data.address_1.city;
					mappedAccount.State = responseObject.data.address_1.state;
					mappedAccount.Country = responseObject.data.address_1.country;
				}

				mappedAccount.PostalCode = responseObject.data.postal_code;
				mappedAccount.CommunicationEmail = responseObject.data.email;
				mappedAccount.SendText = responseObject.data.ok_to_send_text;
				mappedAccount.SendEmails = responseObject.data.allow_emails;
				mappedAccount.PreferredStore.Number = responseObject.data.preferred_store;
				mappedAccount.Language = responseObject.data.language_code;
				mappedAccount.DateOfBirth = responseObject.data.date_of_birth;

				if (responseObject.data.mobile_number) {
					mappedAccount.IsCellPhone = true;
				}

				mappedAccount.PhoneNumber = responseObject.data.phone;
				mappedAccount.MobileNumber = responseObject.data.mobile_number;
				mappedAccount.IsMadAccount = responseObject.data.isMadAccount;
				mappedAccount.IsInactive = responseObject.data.statusCode !== 1;
			}
		}

		return mappedAccount;
	}

	private handleNotFoundOrConflict(error: HttpErrorResponse | any) {
		if (error && error.status === 409) {
			return of(409);
		} else {
			return this.returnErrorEvent(error);
		}
	}

	private returnErrorEvent(error: HttpErrorResponse | any): Observable<never> {
		this.handleErrorLog(error);
		return throwError(error);
	}

	private handleErrorLog(error: HttpErrorResponse | any): void {
		const errMsg: string = error.message ? error.message : error.toString();
		this.logService.fatal('CRM account access has failed', { data: error, message: errMsg });
	}

	get account(): Observable<Account> {
		if (!this._valueSet) {
			this._valueSet = true;

			this.getExistingAccountWithId(this.authenticationService.user.AccountID)
				.pipe(catchError((err, caught) => {
					this._valueSet = false;
					this.handleErrorLog(err);

					// Push Error onto ReplaySubject
					this._account.error(err);
					return throwError(err);
				}))
				.subscribe(userData => {
					// Patch "isMadAccount" flag if false
					if (!userData.IsMadAccount) {
						this.patchIsMadAccountFlag(userData.AccountID, true)
							.subscribe(result => {
								if (result) {
									this.logService.info(userData.AccountID + ": Successfully set isMadAccount flag.");
								}
							});
					}
					this._account.next(userData);
				});
		}

		return this._account;
	}
}
