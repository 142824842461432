import { LanguageParam } from './../../shared/types/LanguageParam';
import { Params } from '@angular/router';
import { Component, AfterViewInit, ViewEncapsulation, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BrandService } from '../../shared/services/brand.service';
import { Unsubscribe } from '../../shared/base/unsubscribe';
import { LoyaltyService } from '../../shared/services/loyalty.service';
import { LogService } from 'ngx-log-service';
import { Site, UtilityFunctions } from '../../utility/utilityfunctions';
import { AuthenticationService } from '../../login/services/authentication.service';

const contentfulPageNotFound = 'error404page';

@Component({
	encapsulation: ViewEncapsulation.None,
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent extends Unsubscribe implements AfterViewInit, OnInit {
	currentBrand: Site;
	urlOverride: string;
	currentLang: LanguageParam = '';

	constructor(private brandService: BrandService,
		private loyaltyService: LoyaltyService,
		private logService: LogService,
		public authenticationService: AuthenticationService) {
		super(new Subject<boolean>());

		this.brandService.siteEmulation.pipe(
			takeUntil(this.killSubscription))
			.subscribe(([brand]) => this.authenticationService.currentBrand = brand);
		this.currentBrand = this.brandService.getBrand();

		this.urlOverride = UtilityFunctions.siteCheck.languageOverrideUrl(this.currentLang, this.brandService.getBrand());

		this.getData();
	}

	redirectPage: string;

	private getData() {

		this.loyaltyService.loyaltyDetails
			.subscribe(loyaltyData => {
				if (loyaltyData && loyaltyData.AccountData) {
					this.redirectPage = '/aboutprogram/' + this.urlOverride;
				} else {
					this.redirectPage = '/login/' + this.urlOverride;
				}
			}, error => {
				this.logService.fatal('Fatal error subscribing to Loyalty Details', { data: error });
			});
	}

	ngOnInit() {
		this.brandService.routeParamsChange$.subscribe((params: Params) => {
			this.currentLang = params['lang'] || '';
		});
	}

	ngAfterViewInit() {
		// Subscribe to changes from URL or DropDown
		this.brandService.siteEmulation.pipe(
			takeUntil(this.killSubscription))
			.subscribe(([newSite, lang]) => {
				// Contentful Content Injection
				this.currentBrand = newSite;
				this.currentLang = lang;
				const entryId = this.currentBrand.entryIds.filter(x => x.name === contentfulPageNotFound)[0].id;
				this.brandService.injectBrand(entryId, lang);
			});
	}
}
