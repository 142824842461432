import { LoyaltyService } from './services/loyalty.service';
import { LoyaltyDetails } from './models/loyaltydetails';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { EntryId, SiteAlias } from './../utility/utilityfunctions';
import { LanguageOption } from './models/language.enum';
import { EnvType } from '../../environments/env.enum';
import { LanguageParam } from './types/LanguageParam';
import { Router, ActivationEnd, ActivatedRoute } from '@angular/router';
import { Component, OnInit, AfterViewInit, HostListener, Output, Input, EventEmitter, Inject } from '@angular/core';
import { environment } from '../../environments/environment';
import { Location, DOCUMENT } from '@angular/common';

import { Site, Sites, UtilityFunctions } from '../utility/utilityfunctions';
import { BrandService } from '../shared/services/brand.service';
import { TrackingService } from '../shared/services/tracking.service';

// Logging
import { LogService } from 'ngx-log-service';
import { AuthenticationService } from '../login/services/authentication.service';
import { takeUntil, filter, map } from 'rxjs/operators';
import { Unsubscribe } from './base/unsubscribe';
import { Subject } from 'rxjs';

const { siteCheck: { getLangFromPath, hideFundrive } } = UtilityFunctions;

export type HamburgerStatus = 'opened' | 'closed';
@Component({
	selector: 'customer-portal-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends Unsubscribe implements AfterViewInit, OnInit {
	contentfulHeader = 'customerPortalHeaderContent';
	brandValues: EntryId[];
	brandSelection: EntryId;
	currentBrand: Site;
	currentLang: LanguageParam = '';
	urlOverride = '';
	loyaltyDetails: LoyaltyDetails = new LoyaltyDetails();
	languageOptions: EntryId[] = [
		{
			name: 'Default',
			id: ''
		},
		{
			name: 'English',
			id: LanguageOption.En
		},
		{
			name: 'French',
			id: LanguageOption.Fr
		},
		{
			name: 'Spanish',
			id: LanguageOption.Es
		}
	];
	selectedLanguage: EntryId = this.languageOptions.find(o => o.id === '');
	headerStickied = false;
	hideHamburger = true;

	@Input() opened = false;
	@Output() onHamburger = new EventEmitter<HamburgerStatus>();
	@HostListener('window:scroll', ['$event'])
	checkScroll() {
		this.headerStickied = window.pageYOffset >= 250;
	}

	constructor(
		@Inject(DOCUMENT) public document: Document,
		private logService: LogService,
		private breakpointObserver: BreakpointObserver,
		public loyaltyService: LoyaltyService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		public authenticationService: AuthenticationService,
		public brandService: BrandService,
		private location: Location) {
		super(new Subject<boolean>());

		if (!environment.production) {
			const sites: EntryId[] = [];
			for (let i = 0; i < Sites.mappings.length; i++) {
				sites.push({
					name: Sites.mappings[i].name,
					id: Sites.mappings[i].alias
				});
			}
			this.brandValues = sites;
		}

		this.brandService.siteEmulation.pipe(
			takeUntil(this.killSubscription))
			.subscribe(([brand]) => this.authenticationService.currentBrand = brand);
		this.currentBrand = this.brandService.getBrand();
	}

	get prodEnvironment(): boolean {
		return environment.production || environment.envName === EnvType.UAT;
	}

	emulateSiteBrand(siteOpt: EntryId) {
		// Push the new brand value onto the Observable stack
		this.brandService.setBrand(siteOpt.id);
		this.brandSelection = siteOpt;

		this.setBrandLogoRedirect();
		this.injectContentful();
	}

	emulateLanguage(option: EntryId<LanguageOption>) {
		const { id } = option;
		this.currentLang = id;
		this.brandService.setLanguage(id);

		const url = this.router.createUrlTree([],
			{
				relativeTo: this.activatedRoute,
			}).toString();
		const prevLang = getLangFromPath(
			url.endsWith('/') ? url : `${url}/`
		);
		let newUrl: string;
		if (!prevLang) {
			newUrl = `${url}/${id}`;
		} else {
			let path = url.split('/');
			path = path.slice(1, path.length - 1);
			newUrl = `${path.join()}/${id}`;
		}
		this.location.replaceState(newUrl);

		this.selectedLanguage = option;
		this.setBrandLogoRedirect();
		this.injectContentful();
	}

	ngOnInit() {
		this.logService.namespace = 'HeaderComponent';

		this.router.events.pipe(
			filter(e => (e instanceof ActivationEnd) && (Object.keys(e.snapshot.params).length > 0)),
			map(e => e instanceof ActivationEnd ? e.snapshot.params : {}),
		).subscribe(params => {
			this.currentLang = params['lang'] || this.currentLang;
			this.selectedLanguage = this.languageOptions.find(o => o.id === this.currentLang);
		});

		this.breakpointObserver
			.observe(['(min-width: 991px)']).pipe(
				takeUntil(this.killSubscription))
			.subscribe((state: BreakpointState) => {
				if (state.matches) {
					// Over 991px
					this.setHamburger(true);
				} else {
					// Under 991px
					this.setHamburger(false);
				}
			});
	}

	ngAfterViewInit() {
		// Subscribe to changes from URL or DropDown
		this.brandService.siteEmulation.pipe(
			takeUntil(this.killSubscription))
			.subscribe(([newSite, lang]) => {
				// Contentful Content Injection
				this.currentBrand = newSite;
				this.currentLang = lang;
				const entryId = this.currentBrand.entryIds.filter(x => x.name === this.contentfulHeader)[0].id;
				this.brandService.injectBrand(entryId, lang);
			});

		this.setBrandLogoRedirect();
	}

	injectContentful(): void {
		this.brandService.injectBrand(this.currentBrand.entryIds.filter(x => x.name === this.contentfulHeader)[0].id, this.currentLang);
	}

	setHamburger(hide: boolean) {
		this.hideHamburger = hide;
	}

	handleClickHamburger(status: HamburgerStatus) {
		this.onHamburger.emit(status);
	}

	setBrandLogoRedirect() {
		let alias = this.currentBrand.alias;

		let corporateLogoLink = document.getElementById('headerBrandLinkMain');
		let corporateLogoLinkScrolled = document.getElementById('headerBrandLinkScrolled');
		let corporateLogoLinkMobile = document.getElementById('headerBrandLinkMobile');

		let corporateLink = "";
		const languageAppend = UtilityFunctions.siteCheck.getLangFromPath(window.location.pathname);

		switch (alias) {
			case 'SAU':
				corporateLink = "https://www.savers.com.au/" + languageAppend;
				break;

			case 'SAV':
			default:
				corporateLink = "https://www.savers.com/" + languageAppend;
				break;

			case 'VDV':
				corporateLink = "https://www.villagedesvaleurs.ca/" + languageAppend;
				break;

			case 'VV':
				corporateLink = "https://www.valuevillage.com/" + languageAppend;
				break;

			case 'VVCA':
				corporateLink = "https://www.valuevillage.ca/" + languageAppend;
				break;
		}

		corporateLogoLink.setAttribute('href', corporateLink);
		corporateLogoLinkScrolled.setAttribute('href', corporateLink);
		corporateLogoLinkMobile.setAttribute('href', corporateLink);
	}

	get hideFundrive(): boolean {
		return hideFundrive(this.currentBrand.alias, this.currentLang);
	}

	get htmlLangIsLong(): boolean {
		const { lang } =  this.document.documentElement;
		return lang.startsWith(LanguageOption.Fr)
		|| lang.startsWith(LanguageOption.Es);
	}

	get isValueVillageBrand(): boolean {
		return this.currentBrand.alias === SiteAlias.ValueVillage;
	}
}
