import { SiteAlias } from './../utility/utilityfunctions';
import { Unsubscribe } from './base/unsubscribe';
import { LanguageParam } from './types/LanguageParam';
import { Params } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { LogService } from 'ngx-log-service';
import { BrandService } from '../shared/services/brand.service';
import { environment } from '../../environments/environment';
import { Site, UtilityFunctions, Sites } from '../utility/utilityfunctions';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LanguageOption } from './models/language.enum';

const { siteCheck: { hideFundrive, isUSBrand, containsLocale } } = UtilityFunctions;

type FooterSites = { [key in SiteAlias]: [string, LanguageParam] };

@Component({
	encapsulation: ViewEncapsulation.None,
	selector: 'customer-portal-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})

export class FooterComponent extends Unsubscribe implements OnInit, AfterViewInit {
	contentfulFooter = 'footerContent';
	brandValues: Object;
	currentBrand: Site;
	currentLang: LanguageParam = '';
	currentYear = new Date().getFullYear();
	footerSites: FooterSites = {
		[SiteAlias.Savers]: ['https://www.savers.com/', ''],
		[SiteAlias.ValueVillage]: ['https://www.valuevillage.com/', ''],
		[SiteAlias.ValueVillageCA]: ['https://www.valuevillage.ca/', ''],
		[SiteAlias.VillageDesValeurs]: ['https://www.villagedesvaleurs.ca/', ''],
		[SiteAlias.SaversAU]: ['https://www.savers.com.au/', '']
	};

	constructor(private logService: LogService,
		private brandService: BrandService) {
			super(new Subject<boolean>());
			if (!environment.production) {
				const sites = [];
				for (let i = 0; i < Sites.mappings.length; i++) {
					sites.push({
						name: Sites.mappings[i].name,
						val: Sites.mappings[i].alias
					});
				}
				this.brandValues = sites;
			}

			this.currentBrand = this.brandService.getBrand();
	}

	ngOnInit() {
		this.logService.namespace = 'FooterComponent';

		this.brandService.routeParamsChange$.subscribe((params: Params) => {
			this.currentLang = params['lang'] || '';
		});
	}

	ngAfterViewInit() {
		// Subscribe to changes from URL or DropDown
		this.brandService.siteEmulation.pipe(
			takeUntil(this.killSubscription))
			.subscribe(([brand, lang]) => {
				this.currentBrand = brand;
				this.currentLang = lang;

				this.injectContentful();
				this.footerSites = this.setBrandSites(lang);
			});
	}

	injectContentful(): void {
		this.brandService.injectBrand(this.currentBrand.entryIds.filter(x => x.name === this.contentfulFooter)[0].id, this.currentLang);
	}

	private setBrandSites(lang: LanguageParam) {
		const sites = {...this.footerSites};
		return Object.keys(sites).reduce<FooterSites>((acc, k) => {
			const [url] = sites[k];

			return {
				...acc,
				[k]: [url, containsLocale(k, lang) ? lang : '']
			};
		}, sites);
	}

	get defaultDisclosureLink(): string {
		return `https://${this.currentBrand.returnUrl}/disclosures`;
	}

	hideFundrive() {
		return hideFundrive(this.currentBrand.alias, this.currentLang);
	}

	hideUSBrandUrls() {
		return !isUSBrand(this.currentBrand);
	}

	hideSocialIcon() {
		return this.currentBrand.alias === SiteAlias.VillageDesValeurs &&
		(this.currentLang === LanguageOption.Fr || this.currentLang === '');
	}
}
