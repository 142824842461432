import { Account } from './account';
import { PointsDetails } from './pointsdetails';
import { RewardsDetails } from './rewardsdetails';
import { SegmentsDetails } from './segmentsdetails';

export class LoyaltyDetails {
	AccountData: Account;
    PointsData: PointsDetails[];
    RewardsData: RewardsDetails[];
    SegmentsData: SegmentsDetails[];
}
