import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { EnvType } from '../../../environments/env.enum';
import { Site, SiteAlias, Sites, UtilityFunctions } from '../../utility/utilityfunctions';

@Injectable()
export class TrackingService {

	static setupGoogleTracking(siteAlias?: string): void {
		let gtmId = TrackingService.getTrackingId(siteAlias);
		TrackingService.populateScriptElements(gtmId);
	}

	private static getTrackingId(siteAlias: string = null): string {

		let currentSite: Site;

		if (siteAlias != null) {
			currentSite = UtilityFunctions.siteCheck.determineSiteByAlias(siteAlias);
		} else {
			let host: string;
			if (window.location.hostname === 'localhost' && environment.envName === EnvType.UAT) {
				host = environment.uatDomain;
			} else {
				host = window.location.hostname;
			}

			currentSite = UtilityFunctions.siteCheck.determineSite(host);
		}

		switch (currentSite.alias) {
			case SiteAlias.Savers:
				return environment.googleTracking.savers.gtmId;

			case SiteAlias.ValueVillage:
				return environment.googleTracking.valueVillage.gtmId;

			case SiteAlias.ValueVillageCA:
				return environment.googleTracking.valueVillageCA.gtmId;

			case SiteAlias.VillageDesValeurs:
				return environment.googleTracking.villageDesValeurs.gtmId;

			default:
				return '';
		}
	}

	private static populateScriptElements(gtmId: string) {

		// Clear existing script elements if existing
		let head = document.getElementsByTagName('head')[0];
		let gtmScriptElementId = 'gtm-script-id';

		let gtmScriptElement = document.getElementById(gtmScriptElementId);

		if (gtmScriptElement != null) {
			head.removeChild(gtmScriptElement);
		}

		window['dataLayer'] = window['dataLayer'] || [];
		window['dataLayer'].push({
			'gtm.start': new Date().getTime(),
			'event': 'gtm.js'
		});

		let firstScript = document.getElementsByTagName('script')[0];
		let gtmScript = document.createElement('script');
		gtmScript.async = true;
		gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + gtmId;
		gtmScript.setAttribute('type', 'text/javascript');
		gtmScript.setAttribute('data-categories', 'essential');

		firstScript.parentNode.insertBefore(gtmScript, firstScript);
	}
}